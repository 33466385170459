export const allowlistAddresses = [
    ["0x474f057ffd4184ce80236d39c88e8ecfe8589931",9999],
["0x5ced3942cf01c4d9bbdd189f5f7edf9789126f41",9999],
["0x87c1016bb56ed2aeb625c71945ca21058f7f94ad",9999],
["0xda81a28eaefba17d9d700b239612ed4a814fdb30",9999],
["0xa33b2df047886c5282ba8f7f2b49d2af3c8ee699",9999],
["0x181e939ab3dff1cdb1c611461b27db574bf55b6e",9999],
["0x1f5066f3a87c117075f57eb3e9839f3999977fdd",9999],
["0x5e9ec24bb195bef8281c676c8d187daf9dd9f27a",9999],
["0x52228e80a47cba170893019370879259304114b3",9999],
["0x750ded8a80cbf179693a0e736f07c0a645ee4889",9999],
["0x9f56bff4459a58488d76df9e42a1c8fe48046f7d",9999],
["0xdcfff5808d0b5b9c5e874fcdb584d593951a57d5",9999],
["0x0c7d841966696af4d1aff9a56424ab938626a443",9999],
["0x87481851f2cacfff8763f49bff57e7afe3ab87dc",9999],
["0x05fb73d5bd5552dda36673a0d53807203456f656",9999],
["0x8cacb17f18ddf34ea35be228f30a2c41e8a11d75",9999],
["0xb6fa0108794dc769ef37bddd01d8d1f1b5e80691",9999],
["0xcd29a73e18395ad3f682e641121d8b28b410229b",9999],
["0x5b87d81f1c6e980b13dd7297fe5a77388568d295",9999],
["0x04b0fd43c4bd6cec82ce90cc0656ae1cc62eccf6",9999],
["0xb4be66d03d47b3d59493a83173bfc4eb20ed6c14",9999],
["0x00687b375430cb84a70b5c5e1adba641aef5d1f9",9999],
["0xddf21318ca81f9dcb3f143b40b95c72328c46892",9999],
["0x0cfee65a6ade0a7fb273abe5106a0473f97107a7",9999],
["0xaadaa45b066a54252a8c5c9b6e434e42de96f607",9999],
["0x91d64f967c0c61949f8e226032275c7246919d0b",9999],
["0x5e11b32360566aad92ea12d0208eb2d23ca3d1cc",9999],
["0x34d72925097e1b1733f7b400fea128911fb63c85",9999],
["0xd54c31b9c201861389a1696553cc73840f8afd5b",9999],
["0x4ce537cae6b32696decae7ac080f766e3174790c",9999],
["0x3b26d7d309cbe605b7caab1f59780d4d9614631a",9999],
["0x423b55048c6a379d154d24b7ce4e9c93527ecae3",9999],
["0x70f6b77d6b75a5e64551760e069cb5fbd1a634bd",9999],
["0x069605ebb5f1c99188ae9a7d8286931dfea824d7",9999],
["0x2b6af1ab9c05b665015f840745625e3621af8287",9999],
["0x3243855e72f0bc21db47838e5607bc1ea23fb040",9999],
["0xf48e449745c787bca4e8e729125307f986ef55d9",9999],
["0x48147b2c543a0eea58da03f54a9da3aa828b1584",9999],
["0xf00477dd916076883c1b984684e795bd7ab26141",9999],
["0x3be5677864ad8851db077ac57e78c0eecc0586b4",9999],
["0x5e01fcb1c4b9ad989d4e46461569edb11606ff16",9999],
["0xd9397e6d6e2b45eaf38182fbe93213bf63a97b50",9999],
["0x32b2591a4be42067879f9d8f31d6f75c731019d6",9999],
["0x43e689252447d5e02ce1327b7b67b77a02b0fb5e",9999],
["0x08566941ed0410dfc5cdf038a4d3ecf813c69378",9999],
["0x0d932be87f249e2848fc2ffc0506c63c6b49aaf2",9999],
["0x7936910b8a03bf9ac7bcef3bb3efbb417a41f0ba",9999],
["0xdcc4b9b0bf2bdeaeed3a48ee1e2877aaaf337a70",9999],
["0x49ef397534f6299e0a8fee75bc16f09726e11ef2",9999],
["0x9bff037e48cf829be50b5212c798b2de84648eef",9999],
["0xae62600d0eb545c445ced4c239a4e297f0c20f2e",9999],
["0xf5279154d214057618aac1e41ff70de5adefcc22",9999],
["0xf8841afb637d2a8220d6caaee0d7af3a13c7e1e7",9999],
["0xea79153254ab6446032d0e3d424cca1d94b0eb10",9999],
["0xed937a015c832b3c067e28c68fd980100175e6e9",9999],
["0x8525c7133df8de40fd9136c6a74b12e87ab0287d",9999],
["0xd306c83acf84318a440a40ebeb5ba681d29a7958",9999],
["0xb5e4324e04e2e24b0f3730fda349c508ab14df64",9999],
["0x75c4d7544dc881596e3679345a7e915f62171dea",9999],
["0xefb31a8efa0d5d898abd7d3586d6bf5d02d86d2f",9999],
["0x56509280bb4f7930dd4d86b27b81a4e0957ba0fd",9999],
["0x2b70e2b6801ab8c75703deca3469792a9718622f",9999],
["0x0f9831a88abb0ea6479f19af7ab6f53a3c490f72",9999],
["0x6a3a46ef70803bfb273116275463c3aea39c6b97",9999],
["0xc94eee312db493bce5d4d1f4000133a980c2ef52",9999],
["0x1977bfc5b49443933b7564fbec3bb2bba0c2afca",9999],
["0xeadc8f8dd75c95c7b92db6d1c7129dd8631b5ea2",9999],
["0x1eaa4c4da20a7b944fd2788f0148b7b1c98495f2",9999],
["0x3a1e499ec1183ecce8141b5e0d3270dd0ef9fd7f",9999],
["0xd0a661d8573d65fdb4912c8359a3f8ed29ebf075",9999],
["0x2f1794d81eaa6c933a5786090787d05e579211c7",9999],
["0x3ed16e42105a74194ed792099d078c1408be6962",9999],
["0x2e1e0ca28a7b924089746e345c71cc1bacc7cd17",9999],
["0xb5c40b3b119f091e97e3ecff68ce0763111a010b",9999],
["0x27f602fa80d2a03ad430315008dfae27c7a7e7ea",9999],
["0x39a3e7812f7d5851fc557c550e08fa75700941e1",9999],
["0xebd459455b588c53a5e37ab77f9250f917624143",9999],
["0x7b12db13370512fd3d73710d730f3823e9c37984",9999],
["0x8fc25676733b4dbd43c05173552966f12a6408ec",9999],
["0x367e6965ca6e063934a17927a5deffe42e1d670e",9999],
["0x86eb8be678274ba4c47af17fa8511c3f9c631bbc",9999],
["0x9293dbd373433f107bb329484d7e9a88bdefd18c",9999],
["0xda8eca8f379a52917d8074ab9ce40b8968e68af8",9999],
["0xb990378362f9e15b3bfa05206cca8f7145ca703c",9999],
["0x0d1de6507595f78bfb86fa1b3288e6fb848b7989",9999],
["0x1c7f1b7650d505a1a10d25b05d0f7b9cbd41c5f7",9999],
["0x36567052392df29da8d5c7cc029589a5cd4c6006",9999],
["0x2b2e31d8ebf01f161fcb161b7847138113359801",9999],
["0xf015aea8a4dea3f6e177729ee13392b963c2a657",9999],
["0x610d8db1a96ecc513b588fc5ca514e33512c91a8",9999],
["0x06d566ff543fb3c4c2062ce61214044f3717e8b1",9999],
["0xcdaf9c60de6e99cea2d8745bf5ea60ad1d9d5ca8",9999],
["0x083b457c0a71d7671b83ba75467f642f1c0aee93",9999],
["0xa18d39c37a6e78b91b1f36fc7d6ed91fcdb597e3",9999],
["0x7d6f4ce9ae580d87d552c2e7c5bb3753518bbc78",9999],
["0x6501f1297577a65cf01296ba54208515270132eb",9999],
["0xae6c486c3aac0d267a31f1b522081feeaa9a9451",9999],
["0xedae9ec3e28352e74d1b519be088a7b59c891459",9999],
["0x7313c6fc4c54e866b8daeccf0acdc3bc825b0a08",9999],
["0xeffa0b6b2ed1a91c14c281f23eabc4e2d7ff6fcf",9999],
["0x9249f7190b5462e497df0107b743594894d0d6ca",9999],
["0x903bddaca2c99a013d88e74e0e781a2b396de72c",9999],
["0xe869095ec626a06158ea2c2c7682c7a217e3931f",9999],
["0xa04ec0add782c755a7f1590929f91d7ae488e862",9999],
["0x801d0ee471f5cc3a884cb99c36fca14b4afebf01",9999],
["0x14b59ef6dd76a78cadf77ffd99ba8e6358759744",9999],
["0x5ba35d07feca20a0d38d25ac6f33a729e23c7276",9999],
["0xb2f8c067269b83b85f6bc6381d916851afeeea73",9999],
["0xf6c465989b9bcb745cad0c9b96ede6bf21849bb7",9999],
["0x84604b053a5595104de92c345d77ecae7cc025f6",9999],
["0x3ac29f05b860a9dd6d07d64afe80e8d3ce51c6e3",9999],
["0xc3d3e4fc0a46b0923c038b84d4dfb50a2ba2724c",9999],
["0x1bc94170db28e43b96d73067445d2cb4b7346c17",9999],
["0x40e929dfa7c4064664db1da679027d3ae5f84792",9999],
["0xfdc8a270227b1d2f1024e27562719828afb4b9b9",9999],
["0xa1a73d3eb156b63fadb92a9025723256235e7d7e",9999],
["0xde966ed974ca94b22814a78a0229022ba1479950",9999],
["0x957913b5aafc0fef76d089073de2f87f71bacc75",9999],
["0x24e060a94de09250f4a293616168f7f20d1f48e5",9999],
["0x685fc609eee12c5f312f8889274a79eb7ec85ed0",9999],
["0x7e45c617350395ba24210c3b0f926f9973177b9f",9999],
["0x154fe6e82c00ef6bb77dc84cb24f9ac14edf10b5",9999],
["0xdfd535aad9f396192df72ce4ff342643772e970c",9999],
["0xaf419e0dfd314da362b8cec2f93c13af5b9f3b3c",9999],
["0x0077a92e1e9d11889ac55c4e9b02fb7a981d189a",9999],
["0xfb0f5755dc920416b486752423c572451af72aed",9999],
["0xa62ef7ab9f3fd15bccd32c9fc6d2321f5a46ec0a",9999],
["0x7cda64fb1f0c58f42c6e165ef69bab9324e2ac3e",9999],
["0x65c911c41b69bb8f71a48a9b284aed19a88576b2",9999],
["0x39ede5033e23c0bc1701d95cd4c0daa891fce02e",9999],
["0x456a616bcf96c19b560cfd39cad4bd84c5abb2e5",9999],
["0xd72cc3fb0307ef7c24955a780d9d78f2a18633b5",9999],
["0x5964e73355f9c44e01175bd868da4c81aa018b41",9999],
["0xd0d5250a553d07f6622b19b04929f64e7876db93",9999],
["0x11d9090e17a2abc00e1a309f42997b274e543cb6",9999],
["0x1565f1e817c07a3d77084fb11f02a24d716d0899",9999],
["0xc740743cbbffabb69d28b678329977b575afc1dd",9999],
["0x402562d8c306679d3603168d9af80c37824734ca",9999],
["0x84989a7bb6803a3e0c6d9a57a43a471d5090d703",9999],
["0x826d434d6fb50a988a7823adc16db3fd11fa29e9",9999],
["0x4bd5a567d7ee2d8ed53248878fb202f68f30d8b6",9999],
["0xbb4a8416079d1854b1591e6b87d680f85d9c7d8b",9999],
["0xec08a126d41dced8679a0d345f528a40d91716b3",9999],
["0xd543d58ee7afa8daa5753fb7b8493a7795bb2baf",9999],
["0xf3c9b972e056f28b31b44b7f3888a0ad4a42af75",9999],
["0x5e0ac74f712aa26257013f7ebe93f5adbbbe6ae2",9999],
["0x3a4fb37422b9ffe2be6bd0409557f09c47742844",9999],
["0x75f50943686e5d429042d4d41304421896d19278",9999],
["0x3c1ddd393857fe177a1208840217b3acdf94a549",9999],
["0x8b08ed7eb0d32a237fab7c201d0a4c8c47bb22f3",9999],
["0x845de2a3a4f5feef44bd7e4a06572885dd5e1d76",9999],
["0x81a7c8f396a6b599856ec7132343f3ff36a69b23",9999],
["0x9b596d047126e776a1b90d47cece5adb5bb84ea4",9999],
["0xb18b8c1eb6301d1cbd69e61fe0529166b324e01d",9999],
["0x0058f3dbc0f909c8c372ed77c5acff22fa6ae7d1",9999],
["0xa2c4d35e6d5258f334ecc18b278e190ef671d699",9999],
["0x32913493f9b2676c35e9e643168b7a8009959c06",9999],
["0x22fceb8fb3fcc94fd5d6f55bca78fa79a2928b11",9999],
["0x5ba47a61dda940b55612a01000eafcfb326651c4",9999],
["0x315723097d3edf731556ab68cc7b87d8c7fb882b",9999],
["0xce6e1ff61f7b0b82f5a22b48891ee364b46e7cec",9999],
["0x23f228ad55c03cd70f416d4f0a70cd467ba510c3",9999],
["0x5393787e73f468ac46880ad2265999337dfa16e0",9999],
["0x21083ffdcb1d74547f16a5af6c9831c1cfaddf07",9999],
["0xbd1eb2c0ab87ca8cac7d5de1dbe1644a5633e692",9999],
["0xf05b026855c8fbc0a2682cbb1b610bafcf54a047",9999],
["0x1a8e50f629255c5a710b69e7c30fc45a07e8368c",9999],
["0x055f44519e85b676605979edc4aa013ec3503149",9999],
["0xfb894c17c1c30c08ced23fc9c6b6f09a00942dcf",9999],
["0x21b5ad7ea02d44dd1e2ce38487da8781b84fd13a",9999],
["0x6ec6cf2006d2ca7e4b2d551347293ff6582b712f",9999],
["0xe6e44c3830b9438b54a64a98d8f284d8412e5c13",9999],
["0x62d35af3365f76b883bc54d05ac1fdb6b5f6d694",9999],
["0xbe9e7f4eca05a83fd210c59db76be779e8003c68",9999],
["0x0933a578518471b796f6d086e7b3ddc3fc6405a4",9999],
["0xcc1f01220b9d9a2bcabb87da9cbc75e2b8a06b8b",9999],
["0x32ff6f0058a492267136a446b71bab6fb586f98f",9999],
["0x31c3ddcedd318ca19bdd878bc08fab936288fb10",9999],
["0x48ef11011ded807f3246bb8c2f4ce9426e09be88",9999],
["0x163ad79dc9a292739291a1a90ffbd4c89b08be2d",9999],
["0x243119dbef6acffe4420c2b49f7a3ec2f8f870f5",9999],
["0x09b17830cb4a734ab8b66d2bb7e6b97111076ea1",9999],
["0x1eee9353b9e977691ae1e44347a2378eb7885d05",9999],
["0xc8b122195f3657578a4705721633308a4264ae8a",9999],
["0x5140ceb53a00db80c3f094fb312fa91fa44faaf3",9999],
["0x59892d4616799b920352876b3726fb5386efd9a4",9999],
["0x53a91eff4a415aa7460a55c8611037b436fb1f12",9999],
["0xf988a7b7ead9d98d0423c43358fac85b4e382755",9999],
["0xd24db0bfb387b0ed05f757e5f3b9ca1c59a82605",9999],
["0x4610ac8837b315b11a23b2c39c12729bcacaa0de",9999],
["0x521c5646c616c125b433a708ee7b28120f69e00d",9999],
["0x5902675ae1180b0fd18fe566ab9a76d2a9f46592",9999],
["0x2f79058836380b4df25d4fc6162af7101ee11a92",9999],
["0x56e740a7ae59c6777d6d0078fa42525a8ec66c87",9999],
["0x1e06e45f528e953d2d59ced2f68c84235e17fb1f",9999],
["0x1058e9a02edc863d20ac99294f812d193748afb4",9999],
["0x34c4b490c998b69e2b50a0a2a30f9e3f759bdd0e",9999],
["0xb16b5bb24c9f48e8f9fc67f3ce8f7b7bee37f471",9999],
["0xb656f158a245f55c132f4afeecc215d38185d295",9999],
["0xe261301543a57944ab3d574f8262383632f6997f",9999],
["0x28380323d81fd76c560fcdad8043de5ec75b779b",9999],
["0x6b61cd47b4563a2016caa8d8b09e95d4f3296e50",9999],
["0xfbea77b50218b9c438722e30648b0acedcf52d41",9999],
["0xe979234d728123f299889a0005ac3b82db75ba7f",9999],
["0x14b0b1a43411434765b294b38d1eb7efc0825cd2",9999],
["0x1b42eaf5fa6cbe192ff7c43615dffbc20541aab3",9999],
["0x92505676b6980207c08725acd85d8a491fa6b194",9999],
["0x1651fa20580e2151e05949677e280429a7af0ada",9999],
["0x108caed2b409b4a73c91e90a0cf1aa75c0c54949",9999],
["0x8a79167a1373062c7e155a3426e15c465c9a474f",9999],
["0xed8434dc16afdeec37cf7a54fba918af00f6ec79",9999],
["0xdc68e2af8816b3154c95dab301f7838c7d83a0ba",9999],
["0xdecf4b112d4120b6998e5020a6b4819e490f7db6",9999],
["0x72d6d33d09c40f64a8ab0b5747e0d39dbb61b36d",9999],
["0xe545c0d41c622bcccc8f103c0607d042a2242350",9999],
["0x8d231c50d6febefa376daadca2550e36e11b64c8",9999],
["0x9956696cf68978d55203f9d6b49464556a5cb8e2",9999],
["0xeeb598ec97207be94a9abec5e6653d3456715292",9999],
["0x797979ae51b5fc1f4b2938eb9f949524896b7f04",9999],
["0x63d1a8a91b1b1e1b1ce0f9fccee2d0df5e74d39b",9999],
["0xbd7de848d25fd81b07bef18c1febe1acb7495dc1",9999],
["0x5d61f268eef978c27d56fc2722111481e6ae21ef",9999],
["0xdba2ea8108467545ed7abe35deec49a5dbad099a",9999],
["0xf08a26090d1cdf2958299a6479db4d9f34616110",9999],
["0xcaf0786ec1b0e39ba4fbe9a759a6e25bd084106d",9999],
["0x44875ec8e7f8264cee17737cb1f1a3ca66bfbce3",9999],
["0x30b425d3a7bbd7b33d11be9af496af5908ebde1d",9999],
["0x3a6de8266241991a94c1648e3bec79cc06f0f38f",9999],
["0x1f65c75c5e34e3ed8798e163280d10c6a09114cf",9999],
["0x674bcf5edf37027cefa860fa8beb09f1f7edc6c6",9999],
["0xa0570c5c1baed2f8e162d7c3786c387223a5f7a5",9999],
["0x2e581428ed71f291a64ac76a03dbe85bad847441",9999],
["0xda46352bce0982745f1d9695a9b8af2418029c95",9999],
["0xec2ef0657495611906bcb32dd98f6b41f50186e5",9999],
["0x77c0a351ddd34dcaa928bd4268f4d816edb5e749",9999],
["0xa4b9492b6cf5570577117adbc6330140645a17cc",9999],
["0x0bfcc6baec1706b3ccf1695b2789a65d4fab3962",9999],
["0x74790145ad7d098b0bbe73dfeb8a911b331b3931",9999],
["0x1ae46b6db7608aca3bdd7454022383621050de64",9999],
["0x45d7ea9f1d520ac0080f670e6c535eec937367f2",9999],
["0x07820f143e56e6191d25304fb4df3e698e15c1fb",9999],
["0x8d7e6a8735496e95fa63bcf09967d9cc01c7fc9b",9999],
["0x9e0696f09bb1e14c8d062e9c44ce36138f870d20",9999],
["0xd68c9023960bbbf1c5b9ba85babde0080a1e1adb",9999],
["0x973a2653d53e263c0ab97e003f52cbcfd9f565e3",9999],
["0xd28ad2cd2d3dbfb67c5ab5dfdeccba4930c83585",9999],
["0x4ff94139209a3945c9e479bee624c22f3b837c1d",9999],
["0x5d2684333aa3cf5c508d4c4cdb1e25aedf46f50d",9999],
["0xb2c2e20d9dd63d259b18670067a6c61f6b6228b6",9999],
["0x9956696cf68978d55203f9d6b49464556a5cb8e2",9999],
["0x82fd3d04b25a13c3cc2b172ecb99394aabd05f64",9999],
["0xcff2386bee297fb5e9b820b2da19d92d454616bd",9999],
["0x41826a6d7b3654ffbb2258380bfe1dbb00c7ffbf",9999],
["0xbeacc99c11d60aed1e7174d0c2494740db30f459",9999],
["0x1f3a68c194f3d10de25a99c07eb22758bc0b564c",9999],
["0x382299a188ccb9ddeb4e1e93a3374a7d29d26f1f",9999],
["0xdc68e2af8816b3154c95dab301f7838c7d83a0ba",9999],
["0x0b4372e936f4c9d86217488ba39b043e77882c25",9999],
["0x01752ea19fd116695a1af18895fb75c5a99ee4e0",9999],
["0x241f483085bd29d175174fad0ef34f07e7f261a2",9999],
["0x2760ee2ceadb9d46416ab2df564907443b5501d7",9999],
["0x84db7383ad9ffdf29957f4b015ac87fe9d3299d1",9999],
["0x5393787e73f468ac46880ad2265999337dfa16e0",9999],
["0xb213110d764658e2277404e85fceb9b4bf8f138a",9999],
["0x7d8321dab77883d8bee57d1add4a8e60262bb66c",9999],
["0x38ce69941e72dca535f32ca2da6d06ac8bed0dbe",9999],
["0x9a1fee8b2b576f198f75493aa8548db879e7fbb6",9999],
["0x2065685879367ff787f19bc0a2bbae2e284dfce4",9999],
["0xe400ce240f8e5f87bb7b6d8398af0b91c6ca5a3e",9999],
["0x32fdb0e48e122140202a0b90d5744e2a83216b40",9999],
["0x972f49020130be2fee1ba7b2db2b2b571f131765",9999],
["0x93c0e945060b26c4e9821241b446ca4183a6711b",9999],
["0x1a40e7863941c5073c2e2794c3281b7c2c67bb4b",9999],
["0x52ac644bfc876055037bdfec6452c4656459754b",9999],
["0xefb39b894ec3ac3818730f665755c82d8f1b5e4a",9999],
["0xaa1e9091d89aff8e7a6bae900653ef11461f2764",9999],
["0xdb021cab4a15ac97a74bab64c74b239ebe4210e8",9999],
["0xfbd4c17c3e468f67c3d48ceaf65255e884dd298c",9999],
["0xc55a6f3c0b0782eb60b9145121293b0baa80a22c",9999],
["0x7fc061a7d3884fcd52e08267dff1c55eb56a17f2",9999],
["0xb53268a331aab043b945a2ab3940f592390b1138",9999],
["0x1824a6f56777485fac2ccffb8d4417dbf1198884",9999],
["0x2df1667a0287c70b75e4047b7b05548154947072",9999],
["0xc4a096dffea26d4036f52561cf4fcaf025236e19",9999],
["0x641354f3796da8f0b5c1f3a2839678baaad3b888",9999],
["0x5c8a037baafe567a0fd1cd45d55361c7a022548c",9999],
["0x60fea19ec1e9e210fbf1bc5e5f19f4fdfdaf8281",9999],
["0x0892e00684b1517863eb831384ea0b0d9f5b18e6",9999],
["0x0fcef2286ea30db658593ed136ab420a97547d4b",9999],
["0x4b116a04bf4491b5c7958e9d0388802e224f9efa",9999],
["0xd674fe4ba3b9bcd2ec84e6706c7d8fc9277d5ce3",9999],
["0xa6f2ae2495db2b84a382d3a6e10bfdc0926b01ad",9999],
["0x03ea0cdb7a09beff94fb6bab2a54be6285190335",9999],
["0xc48e98210722b868d3f2c4ca4a13b34fb0ccc952",9999],
["0x1d8c18063c4924cd2d12890ce55d641433ca4a76",9999],
["0x542438582192332ab04670cffb7fb01ad3a04f27",9999],
["0xaf419e0dfd314da362b8cec2f93c13af5b9f3b3c",9999],
["0xc7d94be11ee9d8b4152a94ecf49c2a4877a3a37e",9999],
["0x98b15ce0b2e123bc45e08287dccdce23e02bf63b",9999],
["0x72d6d33d09c40f64a8ab0b5747e0d39dbb61b36d",9999],
["0x89b30aff406ed51abb7c4a1022a7e45f4ca3b7f8",9999],
["0xc2f9ff1122af79c4a8932e32ceef193a2380b4da",9999],
["0x108caed2b409b4a73c91e90a0cf1aa75c0c54949",9999],
["0x105c405e76f03c915b41a117ce3d9ac6a8921fcc",9999],
["0x1a3326bf12589ee53b3f796c9d376ea3df60d459",9999],
["0x315723097d3edf731556ab68cc7b87d8c7fb882b",9999],
["0x5aee5464960327cbd86adc3685a8c53152c3058a",9999],
["0x866e6b8c7fa693ce981862e3e6cb8c5831909ec7",9999],
["0xe36acbeedfaf5b451eb3f203fc984454c36475e6",9999],
["0xc7a2d165f8da6db2eed1fa02503d918022f399f1",9999],
["0x9c79f5343163128bad9d67172ab5597da44a4fc0",9999],
["0xd4bcb42c1f9aa2feaca5d8ca44fcf766a0458a45",9999],
["0xe581f9444a990fc1e5d7fd8b2a8191d9d72accd9",9999],
["0x6dbf41563bc1cdeb50d9e1b1a9b51e5bbf7ece13",9999],
["0x3c30309732aaae098f40e1c373ae415a34e42ed4",9999],
["0xe730d12649210bf12ea627b192e0248e94edd5b9",9999],
["0x5772bfd0ee58171712be1ceebdd1126b5bb9ea93",9999],
["0x5c3d97f9a3b7938ca043b3a671b4b05291a94823",9999],
["0xa8831e231be5faae7ae97e4fd4ecc1e5e65cdb73",9999],
["0x7a074a149869414f1efceb8c9447cba167174813",9999],
["0x470027d8dc30ef5f4f01c79dab20ad2068859d27",9999],
["0xb73e2d3fed902a20f3feb7b05fc589bd7dee023c",9999],
["0x2eccac6eed3dc85c107eb79261a7309a746d8aaa",9999],
["0x726975b524e28f3657a5ab5b1240c30534aff4f9",9999],
["0x1d4dd22898a37a6b3efbef0218fa46b668ad49b8",9999],
["0x3231010bae0a7c69463c69c8d5a807b8bc5972d8",9999],
["0x687f99efa1bb638ac2ca9ce93e26ee87af6d0c6c",9999],
["0x195dfb522cdeb44440a3c5c7f4f34f03f058d8c5",9999],
["0xce6e1ff61f7b0b82f5a22b48891ee364b46e7cec",9999],
["0xdf253250191649af3b03e6ed6e1f57a16ea95e07",9999],
["0x0cd096562e461caf4f284affdf2545c6ebcf41ae",9999],
["0x4605e4ea6f6de8d0a81c59c3e1dd44d4e92001f8",9999],
["0x5eb0e091161237b8b0fb353d1e5653a279cbd871",9999],
["0x3d0ebdb1d50c8613a953bb201d11f6dadb6fc8f2",9999],
["0x2178038812afd992c18210760f4deffa9ece6dd9",9999],
["0x4ea84a06f011495b99aa7202fdca60443107042f",9999],
["0xadaa8afa703b63d86baed012fed48f7781480565",9999],
["0xaa82ae9b1f4278e3c9c33a0e80d556db0f4ca064",9999],
["0x737cf7fad6c3456297f7bf1aded0206591d4d30d",9999],
["0x87710f9fcf6a89e88ca1be67df81a1fa2b9b5810",9999],
["0xf9932caa88408cdecde68ca9e2a0f43c7b2a3453",9999],
["0xfb0f5755dc920416b486752423c572451af72aed",9999],
["0x0869d5d64522e78950ceb1193da93a336b2f1696",9999],
["0x1ddf8a6d173e59cfb40ee60674ec0e4cd3f7fa49",9999],
["0x92505676b6980207c08725acd85d8a491fa6b194",9999],
["0x3f21f5766267be641489096e897ff79729f63f06",9999],
["0x6143a0d97cc989f56fe3ba72c04359086cf9c0ac",9999],
["0x6ad67abf7a97025a7b144bb5efdd73efe0237435",9999],
["0xb2b4b4edbdadad67925cd8f9c2499a5e50b5bac1",9999],
["0xfe14799ffb1c565acc8b3372ff0326943f26b8ec",9999],
["0x690c14176e31c7c5ae94faab462d9eb47fddd47e",9999],
["0xe85ade296c24b3324bb8f2b0b42dfe74c6f31bc8",9999],
["0xc0ec4257fff48d7f0dc4e503e15c66d7850de3d5",9999],
["0x34201d7b97a463ba7e793fcba27f10ea67ceaf43",9999],
["0x4b0b4ce846d582e61579232ec5178ac6ac0ac491",9999],
["0xe7becdaf0daac268393421c583e8acb2274c61ea",9999],
["0x21a13926be31378e0de9446dddd37cd264c9d6d9",9999],
["0xeed6c2d7dc0cf2e2211cbc424ba9884fbf1b23d0",9999],
["0xed300a5fccc89b61276f7c3ab2ea6173d733ba64",9999],
["0x6a1a6090ce9c522796f632c0a5dbebdc11e8eaef",9999],
["0xd02156d53f3a329d612896fb44efccbac8f5ed8b",9999],
["0x6506363e6b829d46760eb21762d972697e522058",9999],
["0x3872f11ee22ae275b004f754353cafe8abe9662d",9999],
["0x6984620cd653c91ab8cdcb3da535990ebbafddb9",9999],
["0x5e5bb8f85fbcb39756dd9967858b27927e67b1eb",9999],
["0xe5fea09f8d0506ec709767b91533f736d63fb735",9999],
["0xafd57acb6f9f8cd524b544f354dc48c8e13c543b",9999],
["0xf973d337c653216b36c81071232cf8f98cb97190",9999],
["0x26756a1452807ff053407f2b4940613c1a987d77",9999],
["0xc6ff47dcf98c5bc7c8b18b44da24ceed19ff328b",9999],
["0x4213abfb80b984dd6be3c52690d44cbc020eeeed",9999],
["0x9b1aa52daffbcb09312bd00fc20fec3d150befd7",9999],
["0x65ec5e322739096900a9458d59176a8d1004965a",9999],
["0x9857c04532995d8946585c4ced832f5b0355cb51",9999],
["0x493da0cde8cbe456d096d296b88550a703939354",9999],
["0x16ab8f295224630966a774b4557555dfa80324d7",9999],
["0x44f52599798e66d7f45f5a20fffb805cc33c65b4",9999],
["0x3dc95ddff2409ffe6ac3de93f1321772035b12ec",9999],
["0xaacb0cc990da7f9aeae32d9a652c3e0ae1647b5f",9999],
["0xf15b2d971b9b320d931b5264df47af3b4db82981",9999],
["0x52f8d57c3d93ccb19892d3dd7accf36e22bbcb45",9999],
["0x7e701cdb3dc41ccc40f4cfda71d4129981d643bc",9999],
["0x612ca623e264782e3eae3e6efd589c2ff5a2b51c",9999],
["0xcf6a93232bcfc940d6119d78c6a5795dc9492c4b",9999],
["0x9316dca0b409a9e2b1c7dc6dc89db55854adfcc3",9999],
["0x91c8fc23ff40b35c5c42efee401b9d04a90e23e9",9999],
["0xb3538c1942b1d980f52b6c916c22964a1ae5e871",9999],
["0x4035b7a01244c6900e37f8b0950304401be5ee2b",9999],
["0x24d4302ef5fcf3569f843960ee02de2f168a1f53",9999],
["0x816978c6cc3eede8823aed2b1d2db904505bea4b",9999],
["0x370606c2f51caf8145564c8e9ef5cce194585bc2",9999],
["0x9b92310d69dc9d34c78c287cf611587c1a6e7d60",9999],
["0x6e269cfdb481d1804c27fb8eb14110784e8a2132",9999],
["0x85fc16a4eda601fe2b8c7dcda0b2fcae651a5400",9999],
["0x325b3920aa18812034b47fa1a71402713c792842",9999],
["0x0d7acb3efcfe5f6e53a3b2559ecdbc93f9690950",9999],
["0xbe2bd09709b0a847340ba8843fd3d2b09e799dc0",9999],
["0x8251b5364f8e9ceb25d93a9a4d0d34989afad499",9999],
["0xf6a326716fff4564df0bbb61af60fc8254ccc68d",9999],
["0xf23cdb3b06eb07856d1313dd749ba8f1aa720210",9999],
["0xe4ff8566c8b39a843b9617ca7ec078b4f9bdf78f",9999],
["0x4500af60760b899c174986de09887fa7e4a2c7db",9999],
["0x7758b2c138665ee64cfc4ba28e86fd2d8eef1967",9999],
["0x5fcec7c75467a82c5067c6573c5741d21ce63593",9999],
["0xd9bb2ed5903c08eb14fc99a3f6c67985f6cc7839",9999],
["0xf0c608d3b295ea84741d32bcee79e3c5866c0d4d",9999],
["0x895f25a04c7c4f0ce96bde5a83dbfba13982c09c",9999],
["0x7815d1d02d59705a44eaab71b10c8be5300e58de",9999],
["0x011721dae30527b2ecdc49cb350890728cf5aeca",9999],
["0xb3936e5b1d562d0bd6e4028f323740278e67a41b",9999],
["0x62fe1a7be2bd860c24bb3efc3f81395b59cb4b31",9999],
["0x4cb0ecd531161d8d01a1eea84d48f4776a7ea795",9999],
["0xa092c5e23e3aa160d422a815d1be6c0a1f3443bd",9999],
["0xc4a0a47e18358bff1267ce42e7bbfa4adb367387",9999],
["0x215d3c7048379e511de965d74102ccf31208a387",9999],
["0x11c4a39e8db64bf62f32be26791c22a6153c22ac",9999],
["0x7c14545f67218a253153e053ee53ac02a6185631",9999],
["0xe6394c637f94130c7bff7733e93746675a9fcc9c",9999],
["0x8ec5fd1dbf059b4998810cda27f1ce5b36946dbd",9999],
["0x1aeb65479c5cc5f5636e77c9300034858386f08a",9999],
["0xc760fe8e0f361b629ce00da11408d2d08a93b58f",9999],
["0x21925919035b17daa476904aef584adc5bd8f3a5",9999],
["0x3858a227354d75b9f501fa4d4abdc59ee084ad83",9999],
["0xc78eb1a0d46b16701c560c6be8cd73c951c606a8",9999],
["0xa9b40f2ac26c96c9f037a237e9c4d98f6437c03a",9999],
["0x965dc0fe553f7fc025b050db156d9ec2a7d5419e",9999],
["0x47696723f76098d68a0779eaee3af06a2a8ab78d",9999],
["0x7bf5000741d675fe3bdf19aa3053c00be356c1a6",9999],
["0xbd1eb2c0ab87ca8cac7d5de1dbe1644a5633e692",9999],
["0xa17cc03654e43576fd890c3aeeb3670fb70dd540",9999],
["0x02b42d9f6a7133f3c1a2c2b780b422f484636722",9999],
["0x36c383b8a18a299761f4a073a8802729ff62965d",9999],
["0xdad986c16f28690d4af42df34b0cec0fca92230f",9999],
["0xf13bb9b9fc1b4bc4fd26634f30eb05297b961843",9999],
["0xe22bb4508c1c39041e26b10a355ae991621ed91d",9999],
["0x2a6041a3eca0473bad9417cbecd8f69fdc6a3377",9999],
["0xc77795dd3e534792a9e6bb190fc98788ee786f5f",9999],
["0x0d9928d571a95269c0b56247706182adefefcdd9",9999],
["0xf34367fa03b13febf71330075396fc956d8bbea9",9999],
["0x95debaeb0c9eafe11fa5c7c96ab64dd4e436d769",9999],
["0x94c030339448099320153cd2f540508e1f780c97",9999],
["0xe80c7e324e1ea0144b56052a44c84bb253f59048",9999],
["0xea13c532ca56ad8b87d5785dc31ef1c7eb9b6ee8",9999],
["0x41089b971d22ed2be9e0dabb7da3b5a702b737f5",9999],
["0x6f5712663f0f44e2124921300f8f74b37b2d9147",9999],
["0xd1a89c5d59815a1e5c531fa248d21165b0a24a5c",9999],
["0x68fc9f860e2de330113a91ced97ee03cf8174c8a",9999],
["0x521dc78990c39f7c4554cb21655d930cf3ab211e",9999],
["0x0b9e6fc1f4548eed4dd3ee34b93f31a989f458d1",9999],
["0x796cd7f2a56e08440fc15ce67217363d7bb403b9",9999],
["0xbb4dde21a45770306ca03d6da7bf6ac45594173d",9999],
["0x6a9ef46dfb10d2189fc68ac59a0e1023ba6d892f",9999],
["0x7ada76ddc403537fa69a73c5852bcde975b33138",9999],
["0xc2795d2813b57ac81fca3ae0d7e8355a967dce80",9999],
["0xe4964440528b033a145bee4a00208739bd9dabf3",9999],
["0x6270a494a30f3a2df1e0af073a854b4e534abe5a",9999],
["0x3fd37f580f3ddbc8f3d6e9f042ebdaa5afb46561",9999],
["0x2ac68d89c980302a2cb0255beee099b140a3df7c",9999],
["0x88e7c4e1f786c3d4dcba436cae06ce40da038325",9999],
["0xdacc1429cc3682de7d7100d45b5d34ae7ba0b81d",9999],
["0x6183be95b984c3bdc563dba9506897eeca641d08",9999],
["0x5c50d396e7cdc2be26869831bb420df3b1f0202a",9999],
["0x6a1e224599c652045fc8d3da0b084250b252da51",9999],
["0x522cad6bb425bd1b9e3f27537b11116705d9ad0f",9999],
["0xb91791a22eb78cd8f58c4e3b18e198e72e3d57d3",9999],
["0xc7b38e16eb2df9f72f17fa701fded3f48fc221f7",9999],
["0x9d23f181915d005d3fdae985c222c6aacd85433c",9999],
["0x6d6191789d19332a89d8ddf311e6b0d278c9b0b1",9999],
["0x1c826904d9c85e929ee9cbae8385de2c41abad51",9999],
["0xdd4f190d104f89e3b5c937b92afa65048f2c7f33",9999],
["0xa4c3c659dcbf3021d32e378e164b0d1c339843de",9999],
["0x099ab43dffaba97b16e2c66c5eb64980243d4863",9999],
["0xe51484e17a214aad2e52697554e81f04d21ee88e",9999],
["0x760fec469afda3635e967c492e7b3c60b124aee2",9999],
["0xa5e16652d8055fa9851018d8ecd41cf8a6f8f72e",9999],
["0xa6302a89eb162e5a3986bb528cbbe7144eea649f",9999],
["0xf47580e67de9ee9d121bb62b643aaa9ab868516b",9999],
["0x513403811280992409911010adf1a7b4072f71e3",9999],
["0x306e6c43b47740b708a78edcd55cbb65c1152823",9999],
["0xce7890e68b635a6901d7b785f552627d5bc11eb2",9999],
["0x93cf1eab24e5208934167df5b2fa21be4c4150a1",9999],
["0x60026f1b92e885d2f3a49c37224aa48e37e45fbf",9999],
["0x702075cb3cc29e0f3a8fc9e44a8b517d8fae1cb0",9999],
["0xba80c56544a994b976686a25411449662b756825",9999],
["0xe0be3820ac3cc40e0650867d0f387ee113504dc2",9999],
["0xa0eab382c09b38db4c21a0dbfa9282ff955acd52",9999],
["0x5f0ee4730cd92980b254b9889a121548c27ab4df",9999],
["0xea3e254e39cae4dcf41c93baac98f06013c025cd",9999],
["0xa87fbc01a2f445946fd2fdf77287f4020af69129",9999],
["0xaa29c68963e4ae1130777bfb2f34d59de5f5b6ba",9999],
["0x01b27ec780c534ba0fab15509354c3798321273c",9999],
["0x470e6895988ca91479f463cafba048cb7e1eb6e3",9999],
["0x44d354cd189ab5f6f621109a07a0d420a020569f",9999],
["0x907ff2c5f4622a9fda6fa8d9e88f751a29364b18",9999],
["0xf6a05592dcf0a0ca6111d5c7e2f62b9f60b81261",9999],
["0x8ade42eb3e89d92e00f392cca4a16ff4f69853c5",9999],
["0x2c7cd75000407adfe2b33a3f717d85b90219f4f3",9999],
["0xa404934caeb7ef6ea3647ac82e5e1e7273508a6f",9999],
["0x7773e2cc514ca66f357059aefce1d77065b17df4",9999],
["0xdb0494c307439e4f48ad3a8ac776fb27bae04461",9999],
["0x9955d5df9e0d4b2d653989486eaeebb88e43b904",9999],
["0x46f69e787e6e21c5c14a675e468bdd2fde0ec47a",9999],
["0x07109aca84117902f428a90a80f071f71b270bb0",9999],
["0x3155092e9e44749e88126ce7f6b52db24a76925f",9999],
["0x7a23883b26973eb32ae3ca6ccb29513421649a5e",9999],
["0xae9e01cb730e32dd547716106162e2cf5cf1e22b",9999],
["0xba1fe1edf94b9a899ad1e55338366c8af852d2a8",9999],
["0x5e196564f2fcb21f695cc30fff78110ba4d2fdc4",9999],
["0x540a828b07aac91e9b58375bf02d96bdb4f9e28c",9999],
["0x21083ffdcb1d74547f16a5af6c9831c1cfaddf07",9999],
["0xc63aff1a8cc97ea4f9823e287dfbc53f2db24d49",9999],
["0x561a6d987a35d063094c6bd047e07ffcc31e516a",9999],
["0xa5fedfdc49b59604eaaf42565567e26fdcdc2b33",9999],
["0x2ec7c76f5b63962da5ce5366d611b2c79e4cb8cf",9999],
["0x7c4bcde06127527970f0ea3fd263b7fff1f860b5",9999],
["0xec7162c22eac8e6e2aadc7e91f35546c90a53dc8",9999],
["0xb17dcb47afbd56d1395afbe684536796af1877c3",9999],
["0xb963eb1181b61c03d6a0e6c9660c8bc31aaf218a",9999],
["0x31c0b874ebf69c7a00e565f60f4b966dbaab233f",9999],
["0x1e175d596b928ecde2c0fafd26a54f87744175a5",9999],
["0xe62c8334c233d9ffcd968095217f86e25d4eaf8c",9999],
["0xe7adb67d591ca50299be1c36796d3c0361cc91a9",9999],
["0xcaee30eb79974543b119f5e257aee3bb68599cda",9999],
["0x1cbc2eb477679b0161cd35072d7ea530c5cec313",9999],
["0xd1d99dd7ffc789ebb54e11b326de7c974f90345f",9999],
["0x5d42887be6ccf6dc7c39492cfe1a73f3467c0b59",9999],
["0x2911b4d2a554a7a805a12d54c88ba96b0696921e",9999],
["0xe59d9535a635486ec4157c343d0b3fbd4d484fa1",9999],
["0xb5d7cfe079b6a192a072b390fe5d0cf99c5e54d2",9999],
["0xe901e3b50ad6bcc0f6f7e283c3e42de37b167e63",9999],
["0x2768f72ab0f3f9a37145d1cab4bb473f239e8b6e",9999],
["0xc0217aada221b301455d332aaa31a15ae86d1a56",9999],
["0x03aa122abab15aa852b1450596dbacd2345317bd",9999],
["0xb3715d23aa2830396e19d6b75c73768ba83b7557",9999],
["0x51d8b206a7fa809c414e49596c060b397b9d5ad2",9999],
["0x78b1c3ef8a04328829e8151a86080706dc3f0dd4",9999],
["0x5534f6f9d06ce2b2b34973834e8fa15f1545fac7",9999],
["0xd3f0fe827d866774d89eb3b285cad57017f71abd",9999],
["0x5203569263a3b5b447ba639858a9e9768bb7d147",9999],
["0x6e85015c62085c4730e66443099c58a4a193b452",9999],
["0xb728fc78221e7300a9f5548b43321ab1a0a8e8f0",9999],
["0x823f661c416927ce82ce7cbad75b816dd58e2ae2",9999],
["0x33f2604497e3fc9c01a99e87948dde54802496e0",9999],
["0x8bb8ff2a68e7ea21b1d13d408fcf6d12eb0f253e",9999],
["0x675f147a00670aa4afc45c369f228d6c13e58de5",9999],
["0xaaa034272409dc105f8f3c42d50e6597eed3f74d",9999],
["0x88d6e8975659c25ad4a93676fa7ec4ccb63dbd75",9999],
["0x2e3c51446b9aed12e233cf97a9b9b3c1237f9bc3",9999],
["0x2c19a07573c404bfd18a89ec6779029f4c75f628",9999],
["0x6ff7889736467376f53c3836c38c2d62ac0452a8",9999],
["0x0a9ccf43c03730cc9c96c3213ab14a83a5ac1bc4",9999],
["0x432d838c20041f3a16df2497c1d15cc11031ba64",9999],
["0x98f485dc71ba76fc932546fdf89097f1d6a744e8",9999],
["0xfbb137555bb48b874edd6f4c56b99cd98bc963bd",9999],
["0xf65e3c1ad90f96291c200fcce25d32fe214f21cf",9999],
["0x29e1446d062a51bc10733a1f26fdd6b40f2f149e",9999],
["0x0e009926f9c6015e826f4782835eafb033ab4a08",9999],
["0xb4026b6a3a267436b4eb65ae3a0d9724eb6f942a",9999],
["0xd708164f4c5e3f2422b2c57d5c4e39ae4c286ab2",9999],
["0x4758bac8e85ed7150d45161687152f2f6d6f9cb5",9999],
["0x04748073cf0d89ec795ad10b5d3296de8f35dffc",9999],
["0x3f3b1a82bdf41e7fbaf7ab1d4da42d1b235b14e6",9999],
["0xe4dfe1938eb5939f6f35d6f3a4eb23120523aac7",9999],
["0x86901878b47f2347a6f30caf87ab4658c2cc5e2e",9999],
["0x82e1e9666edac4eb98850ce2ef2cf9bfedf2183d",9999],
["0x03c144cb037c1d844e50cbe51ce07acec8076f2c",9999],
["0x5b3aeb7f5cbcd8fee7938398ed67f737b967110d",9999],
["0x971dc6bb8122f51375e1b8c17212f987fb6db306",9999],
["0x08bb98eaa94bfaf43cadecec1a7c2d88946a7e4a",9999],
["0x7dd81083c8ad9e941837d39a2660be3a19712927",9999],
["0x93823eb9cb2209f867fae47f2cd7b392e49fbaa0",9999],
["0x29003cacc3169a6be3e7003800f7d9d3606d8035",9999],
["0x236a21e7ce1490022598379bdd3ff6c627a71b23",9999],
["0xc8710c9ca16c5804bb49bf2999645aaf9e97d862",9999],
["0xf446a62dd0b9e502ed92ce103f0292cd95c97eb3",9999],
["0xa1a45a2b5fcdcdc590f5203d194e563ae6742e32",9999],
["0x2949e2cfd6215ae70a75f8e18cc83712a107fd2a",9999],
["0x65f5f7086d5f5658c0e3e81c53a1b01f1bccb3ec",9999],
["0x80282ca38a1f353588ae02c71303e0060164fc37",9999],
["0x4900c34274ffc9093b656a7f236f7e6b06d8466f",9999],
["0xeec66737e685278e6a2c495a6fe4679a14c68f35",9999],
["0xa28b92f16fbbba0410af54a50470230160657ea7",9999],
["0xbf263dc8676850ad98f20a47c0fcbcc2bed1da89",9999],
["0x1651fa20580e2151e05949677e280429a7af0ada",9999],
["0xb1da855d5f636a2c8dd923cef543ae89c3c154d5",9999],
["0x2722ae324b9f1588ad05f820c19607c5c7675f51",9999],
["0xe6ba46cc70f2172b77b08c51ea8bb1cb732010c1",9999],
["0x01a18b4adbc7a0e19fcfbdf63e85ec6f3c9ce9e7",9999],
["0x61dbfaa7b4676c58d55dbd98547bef913c6cd640",9999],
["0xc026cc1f3571d6fced78d510e9ac382b49c3419e",9999],
["0x44de738ca18b4a05f6e2fad28b51baab90bc17a0",9999],
["0x092a32bac18a83e394f679c3c9c02e5f9d6f5f14",9999],
["0x5e85c03ad8b2c86018d0eddfaebdd55efdcffb0d",9999],
["0x5241373ee464039257354ef1fbb46fcc736e1593",9999],
["0x0373bc705a20cf977f05b29cd56a9fbd3e4b0653",9999],
["0xee7e24fb774aeb2890982158ec03db69513bf2e3",9999],
["0x6b32ddfacc45004531b1f26aff6c95036760fd41",9999],
["0x54105ea638e900f80f3444a1562a92d1a29db1aa",9999],
["0x6f8929bf83338de34b74d5decb6520bea6f668f3",9999],
["0xe7da07a22a3ce8ff79d1bd19ddc797f744b54400",9999],
["0xf557b19b6ad4deb5aa01c51375a1277120d67b61",9999],
["0xf6b1aaebe37c8a733b73100a9a2e2b9fc9188dca",9999],
["0xd05e057768cc31c22a10a30cbb3a78689b57681a",9999],
["0xd7050d8935eaf6c8cde04e9d6c5dd99e23fd3d53",9999],
["0xf626726f6b2aa62450d5d541b3c430356d465cd5",9999],
["0xbdfac522aa64ff8fcb1ced7620478cb7bafa1ea3",9999],
["0x9736a64c2425d95fc0824f9af0cf02b39ff5f7fb",9999],
["0x85c880110e26993ab0ce87defdf0a148802447c7",9999],
["0x55b239003197ec0f57b6acc1208503f9cdeb1c4d",9999],
["0x5a47de3c14f027796b804d8d26d94b1e41546cc4",9999],
["0xd63f98fe9406e3c15bd08fb37a245b451459e6fd",9999],
["0xf3d43d01373880aa14982825791eed3fe143d479",9999],
["0x6d0b7e7e27f023b9f281291386c2e307cd366042",9999],
["0x4feb252ab1fc8a5bd56c264e3188a17a5fe5bbe1",9999],
["0x5f2781e52cdb2652b2685103936a66a6353a9120",9999],
["0x25f4af168a7b463c69b0b7af7a3ed54f99db54cd",9999],
["0x120c509a2fa18605833212047e2921208100341d",9999],
["0x4632933e7a00ff6fc556e776581db4cbbffe9d08",9999],
["0xa5e1a7d9f517bfcb71cbfe18c9a4bbcdd7edfbf6",9999],
["0x68043897c66bdaf4b1ff80f5e1453168d5888c69",9999],
["0xdecf4b112d4120b6998e5020a6b4819e490f7db6",9999],
["0x14b1b6bb769c584b1f4255bd2bcd928cfc87f852",9999],
["0x3537af9a0c4669e5198ae62fe8539da15a586941",9999],
["0xb7f2e4aebdb16ac9b702556b8c9d83e19a05528b",9999],
["0xc3d8e675f8717eb19db90f8558ec2188c24bb5bc",9999],
["0x523c645cd28a9dd0ea59fc1cf67433e8d840c2c6",9999],
["0x4360922d1853dc12f86bb75ddbf728351a73b4f8",9999],
["0x7d7cbeb064da6f407d310903ef4f929eec61d33f",9999],
["0xae21654f75c8089584cd61b01a6a819307c17eae",9999],
["0x7b0f3e1ea31a731da7e35628f1d6d1a772b8f8f3",9999],
["0x51b65ecde122dd57d19680a71080888c10355acd",9999],
["0xacf8507fa4096d618c3deee34a42ce0653ccb4b8",9999],
["0x561959dcab8f22ec510d7c5fdf78d926c3e04508",9999],
["0xff5a1e3e4263138bbcdcd4cbe4d84c678680b049",9999],
["0x34e990eb9f1ec2f2eee7e6fd499267d3b544359c",9999],
["0x22a61e1314687345e35edd1955fa2c21b72d28b1",9999],
["0x78e8333085a997337fb9861213d52235f03c1a19",9999],
["0xd9519750b5a5c3ec64fd20ceab73d05ee99967bb",9999],
["0xd8c6161c459e2222577b275e1e0158091501da4c",9999],
["0x4d7e97a58eb488c8b937280298fce6605c102524",9999],
["0xe736727e6061565ee3fa058728b45d28bbdaaf6b",9999],
["0x0ffb9b96d5a17cefd82b9309f1be5baceca311e0",9999],
["0xebbc76a714844bd38c5463cc95b2655caac01afe",9999],
["0xa080ee3095e3e79a8ce370a0c96a1f31270fa00d",9999],
["0x92868d574ac0439b7355f0409ae7346160764ff1",9999],
["0x171491bc682becdb08119f845b937a7d593d5225",9999],
["0x68c99964b6149aac696efa5aae7518fc978054c8",9999],
["0xbe79e5310684281526b9d4b97c372f01962a7f66",9999],
["0x1dd3270bf0f2b3af93bec29f6da4f8a2f147f16d",9999],
["0x1a86a5c0db76fff89229a1f9d2047987450abbf8",9999],
["0x0ed35594fdb513f955cdde0b0b54a12d619d109c",9999],
["0x58ff40fece43a9f879ce4991929056394b281a1f",9999],
["0x1752f02c09cf732934817e583ce6012d7ff04bf5",9999],
["0x9edb287384bcd1a24d8adb4848df84b3c5f46877",9999],
["0x876f39c33f5e6696a53dc04c71d0b5a5ef862276",9999],
["0x7284f5cc4bb74263d1d83bba57386cc8be498b88",9999],
["0x69e9ec83cba73744fa44f4183e200e038320f4dc",9999],
["0x09426b2f3b6ec474053d708122c828fa0f69997b",9999],
["0x74a8bd2f181eb73e55ef68ade10f42a0e6eed282",9999],
["0xf510d77fea78224810b64efb5ed8b15892f3b341",9999],
["0x8f19927b88b56417d1dbaf0357386a7a29da30e2",9999],
["0xa15a962b061ae3fd64a0adfc53d3016745441b57",9999],
["0x2ab70125fb07fd5af722efa68d4f8f2f846e8b1e",9999],
["0xccfb7b5e748a2e705db012a9bc616b89a3aa3c2c",9999],
["0x96afb2ff4ad20331c43082aee6f50a26ff68f29b",9999],
["0x9d2fcb6abdc0c24057988a1eed53b7b0dd8ceedf",9999],
["0xcf1516aab9bc81376c14b165bbcdaef6d346dcc9",9999],
["0x53596b3f75c8c529115b1fa9eb66f0fdb3c12d57",9999],
["0x166fa7c9d2e936a100c049c86c1bf7527cc1bc5d",9999],
["0x0e9274fdbfc1cbe25aae5b37f78cd7b03328e0c9",9999],
["0x76cb8cd0363e66583eb434939aaf8957f11668db",9999],
["0xba880ca7b5cab0421e2bce90fb39f897f3869bf6",9999],
["0xdad13a6e325fcd4a96c6b214da30e7166e86066d",9999],
["0xe7b3e572069e6732f76fd41b8eed68da44d14dce",9999],
["0x53cc4377d48fbe34330a5b70a4dbe5385f9b5a8b",9999],
["0x2832b6064eedf8bff815f25c00ca1c7d70fc7d6e",9999],
["0xc3f2f7d08ae59e0f6d22bd1c1ee7cd2f11cd21e4",9999],
["0xea4a7d37c4f073dd0b9fef952d5c6e1326a37b22",9999],
["0x2ccb8e4e82127f70db04cd5c3c20131931f289e5",9999],
["0x05f68780e0a2f3dfa8adda1d2cd616220407925c",9999],
["0xe8ac36c960d3c9c80c547464fd2f98d186fedee7",9999],
["0x05acfa5263d5ae9dfb917696d10532a82b32e15f",9999],
["0x82ea13471525dd50d87abb33a53f80d6c82636b7",9999],
["0xdc2be8ffef1239cea6141cad0c598a97878601e5",9999],
["0x3360507f0980d6dc5afb1ca6a718d8e561c2ab82",9999],
["0xf7abd16ec183c2c1f60f578ed9dff2d477c55d15",9999],
["0x73ae17a292eb506d4e0b7333640d6f46cb662a36",9999],
["0x0ff5f8693f50832deeb90243f4bc5c49d38a3866",9999],
["0xb0ceaff8a7a319b43b70b3f4607ffc1eb0f9efb3",9999],
["0x006269c787346755cce92861f269ccab99650ce4",9999],
["0xc6838b48899a41bd3c02d42fa7095ee5f03a4367",9999],
["0x43c9a7e362c6ad43896e962cec9a3096302b154e",9999],
["0x60afb741c6dd4a7c63c683bf0c93c635f9e203a2",9999],
["0xf82ed98ad6b0a92bb1195ae3d2618b8adf2fcc35",9999],
["0x0cd04f2a002db190b46e6a7732bb7a979f8a8f87",9999],
["0x8316c6c389bf9e4b103b5506af1b319af881cdfd",9999],
["0x0af6ecea6785fe64cf2c29d3aa1b6f90dde3aae0",9999],
["0xbf1bf7af7c21033d483c88409dbf3043e7509cb7",9999],
["0x9af3945057d3d9d5db64405cc88d7b148ee03994",9999],
["0x23f228ad55c03cd70f416d4f0a70cd467ba510c3",9999],
["0x458ed1262491b1e18347c0f9c32233d6593b1d2c",9999],
["0x57868cde7d93713abbb074a5f1764741722375fa",9999],
["0x3611c18936e10d9b542ed5c8762b1dfe06ccd504",9999],
["0xcfb231a5022110c6e8f7a56a5dcf8ac2a19708b0",9999],
["0xfdb859f194403f790f6171e24364df0749208d0c",9999],
["0x8878417c2766fe57fa265d73e8ce42b2bc4af5ac",9999],
["0xa2e8f3bea27fe1ffb6ba05f4a7f75310b5e23554",9999],
["0x51a37b8b8f6c96e7fbb17c1d0ddef01295579e28",9999],
["0x4d5809dc91e697ef751f9f7ac8897a1dc7462a37",9999],
["0x20f674770f56188b551f3d4755e3a3ac41e1223d",9999],
["0xae27e72d95d02f236a4eeba47188c8638e27b168",9999],
["0x32b1e59eda159d97068be053cef4707a13dea662",9999],
["0xbbe9d152dec3bdc2c87b1d56300b6e2be8479b24",9999],
["0x73f7fd9060d3836361e8d9b1fb317e7e0da4cc61",9999],
["0x35fd8f95576b32f18927c6475706e8e7906dddec",9999],
["0xe2233bd708eb7cb06cc419dccc09dddfbeb026dc",9999],
["0x3258bc46e16cce309d18b5f1d39bcc8bf534a0b0",9999],
["0x073535518208c914154efb7b769847cbb9a76b61",9999],
["0xc1dcf235b192bdbb2b7b0aee4a2dd360df2f22ce",9999],
["0x4d5e8f83c7b8adc97ea60b14985d054cfda9f55e",9999],
]