// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {

      const blockchain = store.getState().blockchain
      let totalBurnin = await store.getState().blockchain.smartContract.methods.totalBurnin().call();
      let paused = await store.getState().blockchain.smartContract.methods.paused().call();
      let onlyAllowlisted = await store.getState().blockchain.smartContract.methods.onlyAllowlisted().call()
      let userBurnedAmount = await store.getState().blockchain.smartContract.methods.userBurnedAmount(blockchain.account).call()
      let tokensOfOwner = await store.getState().blockchain.smartContract.methods.nftTokensOfOwner(blockchain.account).call();
      let burnedTokenIds = await store.getState().blockchain.smartContract.methods.burnedTokenIds().call();

      dispatch(
        fetchDataSuccess({
          totalBurnin,
          paused,
          onlyAllowlisted,
          userBurnedAmount,
          tokensOfOwner,
          burnedTokenIds,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
